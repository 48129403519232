import React from "react"
import PrivateContent from "../components/privateContent"
import LayoutSearch from "../components/layoutSearch"
import itemsjs from "itemsjs"
import { MenuIcon } from '@heroicons/react/solid'
import { ChevronDoubleLeftIcon } from '@heroicons/react/solid'


// Page markup wrapped in Private component.
const SearchPage = ({ pageContext }) => {
  let catalogue = pageContext.catalogue
  return (
    <LayoutSearch>
        <PrivateContent
          as={ItemsJS}
          callbackPath={'/search'}
          props={ catalogue }
        />
    </LayoutSearch>
  )
}

class ItemsJS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      configuration: {
        searchableFields: ['ref', 'imaxle_sku'],
        sortings: {
          name_asc: {
            field: 'make',
            order: 'asc'
          }
        },
        aggregations: {
          category: {
            title: 'Category',
            size: 10
          },
          condition: {
            title: 'Condition',
            size: 10
          },
          make: {
            title: 'Make',
            size: -1
          },
          model: {
            title: 'Model',
            size: 30
          }
        }
      },
      openMenu : false,
    }

    var newFilters = [];
    
    Object.keys(this.state.configuration.aggregations).map(function (v) {
      newFilters[v] = localStorage.getItem(v) ? JSON.parse(localStorage.getItem(v)) : [];
    })
    
    let lines = props.props;
    let rows = [];
    let makeModel = [];

    lines.forEach(line => {
      // Check a part exists on the line.
      if(line.field_part){
        let part = line.field_part;
        // Get makes.
        let makes = [];
        let models = [];
        part.field_k_types.forEach(ktype => {
          let make = ktype.field_make;
          let model = ktype.field_model
          // Add make to makes for this vehicle        
          if (makes.includes(make) === false) makes.push(make);
          // Add model to makes for this vehicle
          if (models.includes(model) === false) models.push(model);
          
          if ( !( make in makeModel ) ) {
            makeModel[make] = [];
          }
          
          makeModel[make][makeModel[make].length] = model;          
        })
  
        // Build row.      
        rows.push({
          "id": line.line_id,
          "make": makes, 
          "model": models,
          "title": part.title, 
          "category": part.field_part_group, 
          "images": part.field_images, 
          "sku": part.field_sku,
          "ref": line.field_reference,
          "condition": part.field_condition,
          "app_notes": part.field_app_notes,
          "app_summary": part.field_applications_summar,
          "sku_notes": part.field_sku_notes,
          "image_path": (part.field_images.length > 0) ? process.env.GATSBY_DRUPAL_URL + 'sites/default/files/styles/' + process.env.GATSBY_IMG_THUMB + '/public/parts/'.concat(part.field_images[0]) : 'https://placehold.co/100?text=Waiting+for+image',
          "oe_references": part.field_oe_referneces_1,
          "oe_ref_search": part.field_oe_referneces_1.map(ref =>( ref.field_oe_ref )),
          "oe_ref_sanitised_search": part.field_oe_referneces_1.map(ref =>( ref.field_oe_ref_sanitised )),
          "vehicle_fitment": part.field_vehicle_position ? part.field_vehicle_position : "N/A (vehicle)",
          "lateral_fitment": part.field_lateral_fitment ? part.field_lateral_fitment : "N/A (lateral)",
          "side_fitment": part.field_horizontal_fitment ? part.field_horizontal_fitment : "N/A (side)",
          "vertical_fitment": part.field_vertical_fitment ? part.field_vertical_fitment : "N/A (vertical)",
          "stock": part.stock.uk_stock,
          "price": line.field_price ? process.env.GATSBY_CURRENCY + " " + line.field_price  : "No price",
          "imaxle_sku": part.field_sku,
        });        
      }
    });
    
    // Copying this.state using the spread op (...this.state)
    this.state = {
      ...this.state,

      itemsjs: itemsjs(rows, this.state.configuration),
      query: '',
      filters: newFilters,
      per_page: 100,
      make_model: makeModel,
    }
  }

  changeQuery(e) {
    this.setState({
      query: e.target.value
    });
  }

  reset() {
    var newFilters = {};
    Object.keys(this.state.configuration.aggregations).map(function (v) {
      newFilters[v] = [];
      return newFilters;
    })

    this.setState({
      filters: newFilters,
      query: '',
    })
    localStorage.setItem('filters', JSON.stringify(newFilters))
  }

  handleCheckbox = (filterName, filterValue) => event => {
    let newFilters = this.state.filters
    let check = event.target.checked;

    if (check) {
      newFilters[filterName].push(filterValue)
      this.setState({
        filters: newFilters
      })
    } else {
      var index = newFilters[filterName].indexOf(filterValue);
      if (index > -1) {
        newFilters[filterName].splice(index, 1);
        this.setState({
          filters: newFilters
        })
      }
    }

    for (var prop in newFilters){
      localStorage.setItem(prop, JSON.stringify(newFilters[prop]));
    }
    
  }

  menuToggle() {
    this.setState({
      openMenu: !this.state.openMenu
    })
  }

  get searchResult() {
    var result = this.state.itemsjs.search({
      query: this.state.query,
      filters: this.state.filters,
      per_page: this.state.per_page,
      sort: 'make'
    })
    return result
  }

  render() {
    return (
      <main className={"flex"}>
        <title>All Parts</title>
      <div className={`border-r bg-gray-200 p-4 z-10 transform-gpu duration-300 min-h-screen ${this.state.openMenu  ? "translate-x-0 " : "-translate-x-9/10"}`}>
        <div className={"grid grid-flow-row auto-rows-max mr-4"}>
          <form className="mb-2">
            <input type="text" value={this.state.query} onChange={this.changeQuery.bind(this)} placeholder="Search" className={"w-11/12"}/>
          </form>
            <button onClick={this.menuToggle.bind(this)} className={`rounded-full z-50 fixed mt-1 -right-4 bg-gray-800 p-1`}>
            {this.state.openMenu ? (
                <ChevronDoubleLeftIcon style={{ color: "#fff", width: "28px", height: "28px" }} />
              ) : (
                <MenuIcon style={{ color: "#fff", width: "28px", height: "28px" }} />
            )}
            </button>
              {
                Object.entries(this.searchResult.data.aggregations).map(([key, value]) => {
                  let excludeFilters = ['Make', 'Model']
                  if(excludeFilters.indexOf(value.title) === -1){
                  return (
                    <div key={key}>
                      <h5 className="font-bold mt-2 text-indigo-700">{value.title}<span className=""></span></h5>
                      <ul>
                        {
                          Object.entries(value.buckets).map(([keyB, valueB]) => {
                            if(valueB.doc_count > 0){
                              return (
                                <li key={keyB}>
                                  <div className="checkbox block">
                                    <label>
                                      <input className="checkbox" type="checkbox" checked={this.state.filters[value.name].indexOf(valueB.key)>-1 || false} onChange={this.handleCheckbox(value.name, valueB.key)} />
                                      <span className="ml-2">{valueB.key} ({valueB.doc_count})</span>
                                    </label>
                                  </div>
                                </li>
                              )
                            }
                          })
                        }
                      </ul>
                    </div>
                  )}
                })
              }
              {
                <div key={this.searchResult.data.aggregations.make.key}>
                  <h5 className="font-bold mt-2 text-indigo-700">{this.searchResult.data.aggregations.make.title}</h5>
                  <ul className="">
                    {
                      Object.entries(this.searchResult.data.aggregations.make.buckets.sort(function(a, b) {
                        var nameA = a.key.toUpperCase(); // ignore upper and lowercase
                        var nameB = b.key.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                          return -1;
                        }
                        if (nameA > nameB) {
                          return 1;
                        }
                        return 0;
                      })).map(([keyB, valueB]) => {
                        if(valueB.doc_count > 0){    
                          return (
                            <li key={keyB}>
                              <div className="checkbox block">
                                <label>
                                  <input className="checkbox" type="checkbox" checked={this.state.filters[this.searchResult.data.aggregations.make.name].indexOf(valueB.key)>-1 || false} onChange={this.handleCheckbox(this.searchResult.data.aggregations.make.name, valueB.key)} />
                                  <span className="ml-2">{valueB.key} ({valueB.doc_count})</span>
                                </label>
                              </div>
                            </li>
                          )
                          
                        }
                      })
                    }
                  </ul>
                </div>
              }
              {                
              <div key={this.searchResult.data.aggregations.model.key}>
                  <h5 className="font-bold mt-2 text-indigo-700">{this.searchResult.data.aggregations.model.title}</h5>
                  <ul className="">
                    {
                      Object.entries(this.state.filters.make).map(([keyB, valueB]) => {
                        return(
                          Object.entries(this.searchResult.data.aggregations.model.buckets).map(([keyC, valueC]) => {
                            if(this.state.make_model[valueB].indexOf(valueC.key) > -1){
                              if(valueC.doc_count > 0){
                                return (
                                  <li key={keyC}>
                                    <div className="checkbox block">
                                      <label>
                                        <input className="checkbox" type="checkbox" checked={this.state.filters[this.searchResult.data.aggregations.model.name].indexOf(valueC.key)>-1 || false} onChange={this.handleCheckbox(this.searchResult.data.aggregations.model.name, valueC.key)} />
                                        <span className="ml-2">{valueC.key} ({valueC.doc_count})</span>
                                      </label>
                                    </div>
                                  </li>
                                )
                              }
                            }
                          })
                        )
                      })
                    }
                  </ul>
                </div>
              }
              <a className="inline-block px-6 py-2 mr-2 mt-4 text-xs font-medium leading-6 text-center text-white uppercase transition bg-red-700 rounded shadow ripple hover:shadow-lg hover:bg-red-800 focus:outline-none" href="#" onClick={this.reset.bind(this)}>Reset</a>

        </div>
      </div>
      <div className={`overflow-hidden pl-6 w-full flex-1 border-b border-gray-200 ${this.state.openMenu  ? "fixed" : "absolute"}`}>
        <span className="float-right text-right pr-4 ">
          <h1 className={"text-4xl"}>Parts list</h1>
          <h1 className={""}>List of items ({this.searchResult.pagination.total})</h1>
        </span>
      <table className={"table-auto text-sm min-w-full divide-y divide-gray-200"}>
        <thead className={"bg-gray-50"}>
          <tr>
            <th className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:table-cell hidden"}>Details</th>
            <th className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider "}>Application</th>
            <th className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>SKU</th>
            <th className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>Reference</th>
            <th className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>Stock</th>
            <th className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:table-cell hidden"}>Position</th>
            <th className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:table-cell hidden"}>OE references</th>
            <th className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>Price</th>
            <th className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:table-cell hidden"}>Image</th>
          </tr>
        </thead>
        <tbody>
          {
            Object.entries(this.searchResult.data.items).map(([key, item]) => {
              return (
                <tr key={key}>
                  <td className={"px-4 py-2 whitespace-nowrap border md:table-cell hidden"}>
                    <h2 className={"font-bold"}>Product groups</h2>
                      {item.category}
                    <h2 className={"font-bold"}>Condition</h2>
                      {item.condition}
                  </td>
                  <td className={"px-4 py-2 border"}>
                    <h3 className={"font-bold"}>Application summary</h3>
                    <p>{item.app_summary}</p>
                    <h3 className={"font-bold"}>Application notes</h3>
                    <p>{item.app_notes}</p>
                    <h3 className={"font-bold"}>SKU notes</h3>
                    <p>{item.sku_notes}</p>
                  </td>
                  <td className={"px-4 py-2 text-center whitespace-nowrap border"}>
                      {item.imaxle_sku}
                      <br/>
                      
                    <span className={"md:hidden"}>{item.category}</span>
                    <span className={"md:hidden"}>{item.condition}</span>
                    <span className={"md:hidden"}>
                      <img alt="Part thumbnail" className={"w-100 mx-auto md:hidden"} src={item.image_path}/>
                    </span>
                  </td>
                  <td className={"px-4 py-2 whitespace-nowrap border text-center"}>
                    {item.ref}
                  </td>
                  <td className={"px-4 py-2 whitespace-nowrap border text-center"}>
                    {item.stock}
                  </td>
                  <td className={"px-4 py-2 border whitespace-nowrap md:table-cell hidden"}>
                    <p>
                      Vehicle: {item.vehicle_fitment}<br/>
                      Lateral: {item.lateral_fitment} <br/>
                      Side: {item.side_fitment}<br/>
                      Vertical: {item.vertical_fitment}
                    </p>
                  </td>
                  <td className={"px-4 py-2 border whitespace-nowrap md:table-cell hidden"}>
                    <ul>
                      {Object.entries(item.oe_references).map(([key, ref]) => {
                        return (
                          <li key={key}>{ref.field_oe_ref}</li>
                        );
                      })}
                    </ul>
                  </td>
                  <td className={"px-4 py-2 border whitespace-nowrap md:table-cell hidden"}>
                    {item.price}
                  </td>
                  <td className={"px-4 py-2 border whitespace-nowrap md:table-cell hidden"}>
                    <img alt="part thumbnail" className={"w-100 mx-auto"} src={item.image_path}/>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      </div>
    </main>
    )
  }
}

export default SearchPage